<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\eventtemplates\\edit.3278') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.title"
                    :label="$t('src\\views\\eventtemplates\\edit.6726')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.code"
                    :label="$t('src\\views\\eventtemplates\\edit.9699')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <v-radio-group v-model="entity.icon" class="notification-icons">
                <v-radio
                    v-for="(icon, index) in icons"
                    :key="`icon-${index}`"
                    :value="icon"
                >
                    <template v-slot:label>
                        <div :class="`notification-icon notification-icon__${icon}`"></div>
                    </template>
                </v-radio>
            </v-radio-group>
            <v-textarea
                v-model="entity.description"
                :label="$t('src\\views\\eventtemplates\\edit.1903')"
                outlined
            />
            <v-row class="mx-0 px-2">
                <v-toolbar-title>{{ $t('src\\views\\eventtemplates\\edit.1124') }}</v-toolbar-title>
            </v-row>
            <div class="eventTemplates__content__block">
                <p class="px-2">{{ $t('src\\views\\eventtemplates\\edit.4108') }}</p>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.event.subject.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.4737')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.event.subject.en"
                            :label="$t('src\\views\\eventtemplates\\edit.576')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.user.event.text.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.2665')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.user.event.text.en"
                            :label="$t('src\\views\\eventtemplates\\edit.5591')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.event.link.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.1766')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.event.link.en"
                            :label="$t('src\\views\\eventtemplates\\edit.1032')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.event.linkText.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.606')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.event.linkText.en"
                            :label="$t('src\\views\\eventtemplates\\edit.8900')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
            </div>
            <div class="eventTemplates__content__block">
                <p class="px-2">{{ $t('src\\views\\eventtemplates\\edit.email') }}</p>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.template.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.4737')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.template.en"
                            :label="$t('src\\views\\eventtemplates\\edit.8275')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.subject.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.4737')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.subject.en"
                            :label="$t('src\\views\\eventtemplates\\edit.4449')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.user.email.text.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.46')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.user.email.text.en"
                            :label="$t('src\\views\\eventtemplates\\edit.8374')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.link.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.7777')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.link.en"
                            :label="$t('src\\views\\eventtemplates\\edit.1024')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.linkText.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.7519')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.email.linkText.en"
                            :label="$t('src\\views\\eventtemplates\\edit.1034')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
            </div>
            <div class="eventTemplates__content__block">
                <p class="px-2">{{ $t('src\\views\\eventtemplates\\edit.telegram') }}</p>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.telegram.subject.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.4737')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.telegram.subject.en"
                            :label="$t('src\\views\\eventtemplates\\edit.9707')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.user.telegram.text.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.2318')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.user.telegram.text.en"
                            :label="$t('src\\views\\eventtemplates\\edit.8275')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.telegram.link.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.2449')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.telegram.link.en"
                            :label="$t('src\\views\\eventtemplates\\edit.7415')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.telegram.linkText.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.6425')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.user.telegram.linkText.en"
                            :label="$t('src\\views\\eventtemplates\\edit.8873')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
            </div>
            <v-row class="mx-0 mt-3 px-2">
                <v-toolbar-title>{{ $t('src\\views\\eventtemplates\\edit.2143') }}</v-toolbar-title>
            </v-row>
            <div class="eventTemplates__content__block">
                <p class="px-2">{{ $t('src\\views\\eventtemplates\\edit.email') }}</p>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.template.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.id')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.template.en"
                            :label="$t('src\\views\\eventtemplates\\edit.id')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.subject.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.504')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.subject.en"
                            :label="$t('src\\views\\eventtemplates\\edit.6992')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.admin.email.text.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.3185')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.admin.email.text.en"
                            :label="$t('src\\views\\eventtemplates\\edit.2424')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.link.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.3690')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.link.en"
                            :label="$t('src\\views\\eventtemplates\\edit.2163')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.linkText.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.6194')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.email.linkText.en"
                            :label="$t('src\\views\\eventtemplates\\edit.7301')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
            </div>
            <div class="eventTemplates__content__block">
                <p class="px-2">{{ $t('src\\views\\eventtemplates\\edit.telegram') }}</p>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.telegram.subject.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.5512')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.telegram.subject.en"
                            :label="$t('src\\views\\eventtemplates\\edit.9862')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.admin.telegram.text.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.8330')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-textarea
                            v-model="entity.admin.telegram.text.en"
                            :label="$t('src\\views\\eventtemplates\\edit.3851')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.telegram.link.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.8591')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.telegram.link.en"
                            :label="$t('src\\views\\eventtemplates\\edit.2335')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.telegram.linkText.ru"
                            :label="$t('src\\views\\eventtemplates\\edit.5994')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-text-field
                            v-model="entity.admin.telegram.linkText.en"
                            :label="$t('src\\views\\eventtemplates\\edit.5715')"
                            hide-details
                            outlined
                            class="ma-1"
                        />
                    </v-col>
                </v-row>
            </div>
            <v-row
                align="center"
                justify="space-between"
                class="mx-0 mt-3">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\eventtemplates\\edit.8423') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\eventtemplates\\edit.5928') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';

    export default {
        name: 'EventTemplatesEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            imageSrc,
            icons: [
                'speaker',
                'message',
                'ok',
                'cancel'
            ],
            valid: true
        }),
        computed: {
            ...mapState('eventTemplates', ['entity']),
        },
        async mounted() {
            await store.commit('eventTemplates/CLEAR_ENTITY');
            if(this.$route.params.id) {
                await store.dispatch('eventTemplates/get', { id: this.$route.params.id });
            }
        },
        methods: {
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('eventTemplates/SET_ENTITY', this.entity);
                    await store.dispatch('eventTemplates/save');
                    await store.commit('eventTemplates/CLEAR_ENTITY');
                    await this.$router.push({ name: 'event-templates-list' });
                }
            },
            async remove() {
                await store.dispatch('eventTemplates/delete', { id: this.entity._id });
                await store.commit('eventTemplates/CLEAR_ENTITY');
                await this.$router.push({ name: 'event-templates-list' });
            }
        }
    }
</script>

<style lang="scss">
    .eventTemplates__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
    .notification-icons {
        margin-top: 0;

        .v-input--radio-group__input {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    .notification-icon {
        margin-right: 40px;
        width: 44px;
        height: 44px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        &__speaker {
            background-image: url('../../assets/notificationIcons/speaker.svg');
        }
        &__message {
            background-image: url('../../assets/notificationIcons/message.svg');
        }
        &__ok {
            background-image: url('../../assets/notificationIcons/ok.svg');
        }
        &__cancel {
            background-image: url('../../assets/notificationIcons/cancel.svg');
        }
    }
</style>
